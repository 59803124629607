import React from 'react'
import styled from 'styled-components'

function Content({ children }) {
  return <Root>{children}</Root>
}

const Root = styled.div`
  padding: 0 10px;
  margin: 80px auto;
  max-width: 800px;
`

export default Content
