import React from 'react'
import { StaticQuery } from 'gatsby'
import Content from './shared/content'
import { graphql } from 'gatsby'

function getEntries(data) {
  return data.allMarkdownRemark.edges.map(edge => ({
    ...edge.node.frontmatter,
    html: edge.node.html
  }))
}

function Projects() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 1000
          ) {
            edges {
              node {
                frontmatter {
                  path
                  title
                  date
                  tags
                  type
                }
                html
              }
            }
          }
        }
      `}
      render={data => (
        <Content>
          {getEntries(data).map(entry => (
            <div key={entry.slug}>{entry.title}</div>
          ))}
        </Content>
      )}
    />
  )
}

export default Projects
